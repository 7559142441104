import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import TextPageLayout from "../components/text-page-layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TextPageLayout title="About" mdxType="TextPageLayout">
      <h1>{`About`}</h1>
      <p>{`Hey, I'm Preetam!`}</p>
      <p>{`I'm on a lifelong quest to “`}<a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=GNhlNSLQAFE&feature=youtu.be&t=1959"
        }}>{`go as far as one can go`}</a>{`”. Along the way, I enjoy creating things, making music, strength training, and discussing interesting ideas with fellow adventurers.`}</p>
      <p>{`I spend most of my time at `}<a parentName="p" {...{
          "href": "https://mirailabs.io"
        }}>{`Mirai Labs`}</a>{`, an innovation lab and product design studio I started where I get to tinker around with cool tech. Our most well-known invention is `}<a parentName="p" {...{
          "href": "https://maruos.com"
        }}>{`Maru`}</a>{`, a novel approach to personal computing that turns mobile devices into desktop PCs. If you'd like to work together to build something great, please `}<a parentName="p" {...{
          "href": "/contact"
        }}>{`get in touch`}</a>{`.`}</p>
      <p>{`Honestly, my ultimate ambition is simply to build a real-life gravity chamber so I can `}<a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=lylBz2H80FE"
        }}>{`train like Goku`}</a>{`. (For now, I'll settle for weightlifting.)`}</p>
      <p>{`If you're interested in following my work, here are some other places I can be found:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://twitter.com/preetamdsouza"
          }}>{`Twitter`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/pdsouza"
          }}>{`GitHub`}</a></li>
      </ul>
    </TextPageLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      